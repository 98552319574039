import React from "react";

import { Input, Select, Checkbox, Radio } from "antd";

const { TextArea } = Input;

const ReadOnlyTemplateRenderer = ({ question }) => {
  const sharedInputProps = (props) => {
    return {
      style: {
        maxWidth: "80%",
        borderBottom: "5px solid var(--clr-primary)",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderRadius: props === "short" || props === "paragraph" ? "10px" : "0",
      },
    };
  };

  // const onDownload = (url) => {
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "file");
  //   document.body.appendChild(link);
  //   link.click();
  // }

  return (
    <div className="make__request__form__item">
      <div className="d-flex flex-column gap-1">
        <h5 className="">
          {question.questionTitle}
          {question.required && <span className="text-danger">*</span>}
        </h5>
        {question?.isQuestionDescription && (
          <small>{question.questionDescription}</small>
        )}
      </div>

      {question.questionType === "radio" ? (
        <Radio.Group
          className="d-flex flex-column gap-1"
          value={question?.answer}
         
        >
          {question?.questionOptions?.map((option, index) => (
            <Radio key={index} value={option} className=" fs-6">
              {option}
            </Radio>
          ))}
        </Radio.Group>
      ) : question.questionType === "checkbox" ? (
        <Checkbox.Group
          className="d-flex flex-column gap-1"
          value={question?.answer}
         
        >
          {question?.questionOptions?.map((option, index) => (
            <Checkbox key={index} value={option} className=" fs-6">
              {option}
            </Checkbox>
          ))}
        </Checkbox.Group>
      ) : question.questionType === "paragraph" ? (
        <TextArea
          rows={4}
          placeholder={"Paragraph Answer Here"}
          value={question?.answer}
          {...sharedInputProps("paragraph")}
         
        />
      ) : question?.questionType === "short" ? (
        <Input
          size="large"
          placeholder={"Short Answer Here"}
          value={question?.answer}
          {...sharedInputProps("short")}
         
        />
      ) : question?.questionType === "numeric" ? (
        <input
          type="number"
          placeholder={"Numeric Answer Here"}
          value={question?.answer}
          {...sharedInputProps("numeric")}
         
        />
      ) : question?.questionType === "date" ? (
        <input
          type="date"
          placeholder={"Date Answer Here"}
          value={question?.answer}
          {...sharedInputProps("date")}
         
          className="w-100"
        />
      ) : question?.questionType === "dropdown" ? (
        <Select
          showSearch
          size="large"
          placeholder="Select an option"
          optionFilterProp="children"
          value={question?.answer}
          {...sharedInputProps("short")}
          options={
            question?.questionOptions && question?.questionOptions.length > 0
              ? question?.questionOptions?.map((option, index) => ({
                  label: option,
                  value: option,
                }))
              : []
          }
         
        />
      ) : question?.questionType === "file" ? (
        question?.answer?.map((link, index) => (
          <a href={link} key={index} download>
            Download File {index + 1}
          </a>
        ))
      ) : null}
    </div>
  );
};

export default ReadOnlyTemplateRenderer;
