import React, { useEffect, useState } from "react";
import "../../../styles/teamAnnualLeave.css";
import { AiOutlineSearch } from "react-icons/ai";
import {
  Avatar,
  Button,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Space,
  Steps,
  Table,
} from "antd";
import {
  useGetTeamLeaveHistory,
  useGetTrackLeave,
} from "../../../util/usershookActions";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { team_leave_history } from "../../../util/selectors/userSelectors";
import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";
import dayjs from "dayjs";

export default function TeamAnnualLeave() {
  useUserCleanUp();
  const [enabled, setEnabled] = useState(true);
  const [teamHistoryData, setTeamHistoryData] = useState([]);
  const [pageTeamHistory, setPageTeamHistory] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });

  const onStart = (date) => {
    console.log({ date });
    console.log(dayjs(date.Sd).format("YYYY-MM-DD"));
    setDates(() => ({
      ...dates,
      from: dayjs(date?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ from });
  };
  const onEnd = (date_end) => {
    console.log({ date_end });
    setDates(() => ({
      ...dates,
      to: dayjs(date_end?.$d).format("YYYY-MM-DD"),
    }));
    console.log({ to, from });
  };
  useEffect(() => {
    if (dates.from && dates.to) {
      setFrom(dates.from);
      setTo(dates.to);
    }
  }, [dates]);

  useEffect(() => {
    if (from && to) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
    console.log({ to, from });
  }, [from, to]);

  const { refetch } = useGetTeamLeaveHistory(
    enabled,
    setEnabled,
    pageTeamHistory,
    from,
    to
  );
  const team_history = useShallowEqualSelector(team_leave_history);
  console.log({ team_history });

  console.log({ team_history });

  const handleDates = (e) => {
    setDates((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    if (dates.from && dates.to) {
      setTo(dates.to);
      setFrom(dates.from);
      setEnabled(true);
      // refetch()
      setDates({
        from: "",
        to: "",
      });
    }
  }, [dates]);
  useEffect(() => {
    console.log({ dates });
  }, [dates]);

  useEffect(() => {
    if (Object.keys(team_history)?.length) {
      const new_array = team_history?.payload?.data.map((leave) => {
        return {
          key: leave.id,
          name: `${leave.user.first_name} ${leave.user.last_name}`,
          ["leave type"]: leave.leave_group.title,
          ["start date"]: leave.start_date,
          ["end date"]: leave.end_date,
          ["backup employee"]: `${leave.backup_employee.first_name} ${leave.backup_employee.last_name}`,
         
          status: leave.status,
          ["approval date"]: leave.approval_date || "N/A",
          id: leave.id,
        };
      });
      setTeamHistoryData(new_array);
      console.log({ new_array });
    }

    // console.log({leaveHistoryData})
  }, [team_history]);
  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder={`Filter ${dataIndex}`}
              value={selectedKeys[0]}
              onPressEnter={() => {
                confirm();
              }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
            ></Input>
            <Space className="my-1">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
                // icon={<AiOutlineRedo />}
              >
                Reset
              </Button>
            </Space>
          </>
        );
      },
      filterIcon: () => {
        return <AiOutlineSearch />;
      },
      onFilter: (value, record) => {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    };
  }
  const teamLeaveHistory = [...teamHistoryData];
  const teamLeaveHistoryColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Leave type",
      dataIndex: "leave type",
      key: "leave type",
      ...getColumnSearchProps("leave type"),
    },
    {
      title: "Start date",
      dataIndex: "start date",
      key: "start date",
      ...getColumnSearchProps("start date"),
    },
    {
      title: "End date",
      dataIndex: "end date",
      key: "end date",
      ...getColumnSearchProps("end date"),
    },

    {
      title: "Backup employee",
      dataIndex: "backup employee",
      key: "backup employee",
      ...getColumnSearchProps("backup employee"),
    },
   
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
    },
    {
      title: "Approval date",
      dataIndex: "approval date",
      key: "approval date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      render: (_, record) => (
        <EachAction
          record={record}
          // NugdeNext={NugdeNext}
          // ApproveregetRequest={ApproveregetRequest}
          // refetch={refetch}
        />
      ),
    },
  ];

  function handlePaginationTeamHistory(page) {
    setPageTeamHistory(page);
    setEnabled(true);
  }

  return (
    <div className="container-fluid">
      <section className="app__section py-4">
        <main className="teamLeave_main">
          <div className="d-flex justify-content-between">
            <h4 style={{ width: "500px" }}>my team leave history</h4>
            {teamHistoryData.length > 0 && (
              <div
                className="mb-2 text-end d-flex justify-content-end"
                style={{ width: "100%" }}
              >
                <div
                  className="mb-2 text-end d-flex justify-content-end"
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    onChange={onStart}
                    className="me-2"
                    allowClear={true}
                  />
                  <DatePicker onChange={onEnd} allowClear={true} />
                </div>
              </div>
            )}
          </div>
          <hr className="teamLeave_line" />

          {teamHistoryData.length > 0 ? (
            <>
              <Table
                dataSource={teamLeaveHistory}
                columns={teamLeaveHistoryColumns}
                pagination={false}
              />
              <div className="mt-3 d-flex justify-content-end">
                {team_leave_history?.payload?.meta?.last_page > 1 ? (
                  <Pagination
                    total={team_leave_history?.payload?.meta?.last_page}
                    pageSize={1}
                    onChange={handlePaginationTeamHistory}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <div className="w-100 mx-auto">
              <NoContent
                illustration="/assets/leaveimage.png"
                width={"250px"}
                message={"No team leave history found"}
              />
            </div>
          )}
        </main>
      </section>
    </div>
  );
}

function EachAction({ record }) {
  const [showModalTrack, setShowModalTrack] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [track_details, setTrackDetails] = useState({});
  const [current, setCurrent] = useState(0);
  console.log({ record });

  const { isLoading, data } = useGetTrackLeave(enabled, setEnabled, record.id);

  // console.log({ track_details });

  useEffect(() => {
    if (data) {
      setTrackDetails(data?.payload || {});
      if (data?.payload?.backup_employee_approval === "PENDING") {
        setCurrent(0);
      } else if (data?.payload?.line_manager_approval === "PENDING") {
        setCurrent(1);
      } else if (data?.payload?.hr_approval === "PENDING") {
        setCurrent(2);
      } else {
        setCurrent(0);
      }
    }
  }, [data]);

  const handleModalTrack = (e) => {
    e.preventDefault();
    setShowModalTrack(!showModalTrack);
  };

  return (
    <>
      <Button
        className={`rounded p-1 px-2 border border-1 text-white me-1 bg-primary`}
        onClick={handleModalTrack}
      >
        Track Request
      </Button>
      <Modal
        open={showModalTrack}
        footer={null}
        onCancel={handleModalTrack}
        maskClosable={false}
        width={700}
      >
        <h5>Leave Tracker</h5>
        <hr />

        {isLoading ? (
          <SkeletonUI number={1} />
        ) : Object.keys(track_details).length ? (
          <div className="mt-5 mb-4">
            <Steps
              current={current}
              labelPlacement="vertical"
              items={[
                {
                  title: (
                    <>
                      <p className="text-nowrap mt-3 ">Back-up Employee</p>
                    </>
                  ),
                  description: (
                    <>
                      {track_details?.backup_employee_approval === "PENDING" ? (
                        <span className="text-muted">Pending</span>
                      ) : (
                        <span className="text-success">Approved</span>
                      )}
                      <Avatar
                        size={64}
                        src={
                          track_details?.backup_employee?.avatar ||
                          "/assets/man.png"
                        }
                      />
                      ,
                    </>
                  ),
                },
                {
                  title: (
                    <>
                      <p className="text-nowrap mt-3 ">Line Manager</p>
                    </>
                  ),
                  description: (
                    <>
                      {track_details?.line_manager_approval === "PENDING" ? (
                        <span className="text-muted">Pending</span>
                      ) : (
                        <span className="text-success">Approved</span>
                      )}
                      <Avatar
                        size={64}
                        src={
                          track_details?.line_manager?.avatar ||
                          "/assets/man.png"
                        }
                      />
                      ,
                    </>
                  ),
                },
                {
                  title: (
                    <>
                      <p className="text-nowrap mt-3 ">HR</p>
                    </>
                  ),
                  description: (
                    <>
                      {track_details?.hr_approval === "PENDING" ? (
                        <span className="text-muted">Pending</span>
                      ) : (
                        <span className="text-success">Approved</span>
                      )}
                      <Avatar
                        size={64}
                        src={track_details?.hr?.avatar || "/assets/man.png"}
                      />
                    </>
                  ),
                },
              ]}
            />
          </div>
        ) : (
          <NoContent message={"No track record available"} />
        )}
      </Modal>
    </>
  );
}
