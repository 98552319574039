import React from 'react';
import { useState, useEffect } from 'react';
import { Select, DatePicker, Tabs } from 'antd';
import HocHeader from '../../ui/HocHeader';
import AllRequestsTemplateHistoryTable from './AllRequestsTemplateHistoryTable';

import { LoadingOutlined } from '@ant-design/icons';

import {
  useGetAllEmployeesRequestHistoryOthers,
  useGetRequestTemplates,
} from '../../../util/usershookActions';
import dayjs from 'dayjs';

import SkeletonUI from '../../ui/Skeleton';
import NoContent from '../../ui/NoContent';

const AllRequestsHistory = () => {
  const [enabled, setEnabled] = useState(true);
  const [status, setStatus] = useState('approved');
  const [singleTemplateEnabled, setSingleTemplateEnabled] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const {
    data: requestTemplates,
    isLoading: isLoadingRequestTemplates,
    refetch: refetchRequestTemplates,
  } = useGetRequestTemplates(enabled, setEnabled);

  const {
    data: allEmployeesRequestHistoryOthers,
    refetch: refetchAllEmployeesRequestHistoryOthers,
    isLoading: isLoadingAllEmployeesRequestHistoryOthers,
  } = useGetAllEmployeesRequestHistoryOthers(
    singleTemplateEnabled,
    setSingleTemplateEnabled,
    selectedTemplateId,
    from,
    to,
    status
  );

  const onChange = (value) => {
    // alert('all');
    setSelectedTemplateId(value);
    setSingleTemplateEnabled(true);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (selectedTemplateId && status) {
      setSingleTemplateEnabled(true);
    }
  }, [selectedTemplateId, status]);

  useEffect(() => {
    // console.log({ from, to });
    if (from && to) {
      refetchAllEmployeesRequestHistoryOthers();
    } else if (singleTemplateEnabled) {
      setSingleTemplateEnabled(true);
    }
  }, [
    from,
    refetchAllEmployeesRequestHistoryOthers,
    to,
    singleTemplateEnabled,
  ]);

  useEffect(() => {
    setFrom('');
    setTo('');
  }, [selectedTemplateId]);

  // console.log({allEmployeesRequestHistory});

  const onChangeTab = (key) => {
    console.log(key);
    setStatus(key);
    setSingleTemplateEnabled(true);
  };
  const items = [
    {
      key: 'approved',
      label: 'Approved Request',
      children: (
        <AllRequestsTemplateHistoryTable
          allEmployeesRequestHistory={allEmployeesRequestHistoryOthers}
        />
      ),
    },
    {
      key: 'pending',
      label: 'Pending Request',
      children: (
        <AllRequestsTemplateHistoryTable
          allEmployeesRequestHistory={allEmployeesRequestHistoryOthers}
        />
      ),
    },
    {
      key: 'rejected',
      label: 'Rejected Request',
      children: (
        <AllRequestsTemplateHistoryTable
          allEmployeesRequestHistory={allEmployeesRequestHistoryOthers}
        />
      ),
    },
    {
      key: 'all',
      label: 'All Request',
      children: (
        <AllRequestsTemplateHistoryTable
          allEmployeesRequestHistory={allEmployeesRequestHistoryOthers}
        />
      ),
    },
  ];

  return (
    <>
      <div>
        <HocHeader
          module_title="Request Management"
          module_class="performance-header"
          module_name={'All Requests History'}
          module_description="Access a comprehensive history of all your requests, providing clear insights and records at your fingertips"
          module_img="/assets/request-management.png"
        />
      </div>

      <main className="main__wrapper">
        <div className="container-fluid">
          <div className="app__section py-4 mt-3">
            <div
              className="d-flex justify-content-between"
              style={{
                borderBottom: '1px solid #d9d9d9',
                paddingBottom: '1.5rem',
              }}
            >
              <div className="">
                <h3 className="h4">Request History</h3>
                <p>View History of any preferred Request</p>
              </div>

              <div className="d-flex gap-2 justify-content-end align-items-center">
                <Select
                  showSearch
                  size="large"
                  placeholder="Select a template"
                  optionFilterProp="children"
                  value={selectedTemplateId}
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  style={{
                    width: 250,
                  }}
                  options={requestTemplates?.payload?.map((template) => ({
                    label: template.template_name,
                    value: template.id,
                  }))}
                />
                {isLoadingRequestTemplates ? <LoadingOutlined /> : null}
              </div>
            </div>

            {selectedTemplateId && (
              <div className="d-flex gap-5 mt-5">
                <div className="d-flex flex-column gap-2">
                  <label className="fw-bold fs-6">From</label>
                  <DatePicker
                    size="large"
                    onChange={(date, dateString) =>
                      setFrom(date?.format('YYYY-MM-DD'))
                    }
                    value={from ? dayjs(from, 'YYYY-MM-DD') : null}
                    allowClear
                  />
                </div>

                <div className="d-flex flex-column gap-2">
                  <label className="fs-6 fw-bold">To</label>{' '}
                  <DatePicker
                    size="large"
                    onChange={(date, dateString) =>
                      setTo(date?.format('YYYY-MM-DD'))
                    }
                    value={to ? dayjs(to, 'YYYY-MM-DD') : null}
                    allowClear
                  />
                </div>
              </div>
            )}

            <div className="d-flex flex-column gap-3 justify-content-center w-100 mt-5">
              {isLoadingAllEmployeesRequestHistoryOthers ? (
                <SkeletonUI number={5} />
              ) : selectedTemplateId &&
                allEmployeesRequestHistoryOthers &&
                allEmployeesRequestHistoryOthers.payload ? (
                <div className="d-flex flex-column gap-3">
                  <h5 className="mt-3">
                    {allEmployeesRequestHistoryOthers?.payload?.template_name}
                  </h5>

                  <Tabs
                    defaultActiveKey={status}
                    items={items}
                    onChange={onChangeTab}
                  />
                </div>
              ) : allEmployeesRequestHistoryOthers &&
                allEmployeesRequestHistoryOthers.payload ? (
                <NoContent
                  width={'400px'}
                  message={'No Request History Found'}
                  illustration={'/assets/selected.png'}
                />
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center gap-2"
                  style={{
                    height: '70vh',
                  }}
                >
                  <img
                    src="/assets/reqmanagement.png"
                    alt="reqmanagement"
                    width="200"
                  />
                  <h4 className="h5">Welcome to History</h4>
                  <p>You can view histories of all available requests here</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AllRequestsHistory;
