import React from 'react';
import { useState, useEffect } from 'react';

import { Select, Button, Form } from 'antd';

import TemplateRenderer from './TemplateRenderer';
import ExpenseReportTemplate from '../../predefined-template/expense-report-template/ExpenseReportTemplate';

import {
  useGetRequestTemplates,
  useGetRequestTemplateById,
} from '../../../../util/usershookActions';

import { makeARequest } from '../../../../redux/users/userActions';

import SkeletonUI from '../../../ui/Skeleton';

import { LoadingOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';

const MakeNewRequest = () => {
  const [enabled, setEnabled] = useState(true);
  const [singleTemplateEnabled, setSingleTemplateEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedTemplateObject, setSelectedTemplateObject] = useState({});
  const [answers, setAnswers] = useState([]);
  const [expenseReportAnswers, setExpenseReportAnswers] = useState({});
  const [creds, setCreds] = useState();

  const dispatch = useDispatch();

  const { data: requestTemplates, isLoading: isLoadingRequestTemplates } =
    useGetRequestTemplates(enabled, setEnabled);

  const { data: requestTemplateById, isLoading: isLoadingRequestTemplateById } =
    useGetRequestTemplateById(
      singleTemplateEnabled,
      setSingleTemplateEnabled,
      selectedTemplateId
    );

  const onChange = (value) => {
    setSelectedTemplateId(value);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (answers.length > 0) {
      setCreds({
        requestSubject: answers[0].answer,
        answers: [...answers],
      });
    } else {
      setCreds(expenseReportAnswers);
    }
  }, [answers, expenseReportAnswers]);

  const handleSubmitFilledRequestForm = async () => {
    console.log({ creds });
    setLoading(true);

    makeARequest(dispatch, creds, selectedTemplateId).then((res) => {
      setLoading(false);
      if (res?.status === 'success') {
        setAnswers([]);
        setExpenseReportAnswers({});
        console.log('resLoad', res);
      }
    });
  };

  const handleSetAnswer = (id, answer) => {
    const updatedAnswers = answers.map((question) => {
      if (id === question.question_id) {
        return {
          ...question,
          answer: answer,
        };
      }
      return question;
    });

    setAnswers(updatedAnswers);
  };

  useEffect(() => {
    if (requestTemplateById) {
      const new_array = requestTemplateById?.payload?.questionsItems?.map(
        (question) => {
          return {
            question_id: question.id,
            answer: question.answer || '',
          };
        }
      );
      setAnswers(new_array);
    }
  }, [requestTemplateById]);

  useEffect(() => {
    if (requestTemplateById?.payload?.templateType === 'expense-report') {
      setAnswers([]);
      setExpenseReportAnswers((prevExpenseReportAnswers) => {
        return {
          ...prevExpenseReportAnswers,
          templateType: 'expense-report',
          answers: {
            expensePeriod: {
              start_date: '',
              end_date: '',
            },
            expenseItems: [
              {
                id: 1,
                expense_date: '',
                description: '',
                expense_type: '',
                expense_evidence: '',
                amount: '',
              },
              {
                id: 2,
                expense_date: '',
                description: '',
                expense_type: '',
                expense_evidence: '',
                amount: '',
              },
            ],
            currency: {
              name: 'NGN',
              symbol: '₦',
            },
            total_expenses: 0.0,
            advance_collected: 0.0,
            balance_amount: 0.0,
          },
        };
      });
    }
  }, [requestTemplateById?.payload?.templateType]);

  useEffect(() => {
    if (requestTemplateById) {
      setSelectedTemplateObject(requestTemplateById?.payload);
    }
  }, [requestTemplateById]);

  useEffect(() => {
    if (selectedTemplateId) {
      setSingleTemplateEnabled(true);
    }
  }, [selectedTemplateId]);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: '1px solid #d9d9d9',
            paddingBottom: '1.5rem',
          }}
        >
          <div className="">
            <h3 className="h4">Request Form</h3>
            <p>
              Select and view already existing forms of your preferred choice
            </p>
          </div>

          <div className="d-flex gap-2 justify-content-end align-items-center">
            <Select
              showSearch
              size="large"
              placeholder="Select a template"
              optionFilterProp="children"
              value={selectedTemplateId}
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              style={{
                width: 250,
              }}
              options={requestTemplates?.payload
                ?.filter((template) => template?.active === 1)
                ?.map((template) => ({
                  label: template.template_name,
                  value: template.id,
                }))}
            />
            {isLoadingRequestTemplates ? <LoadingOutlined /> : null}
          </div>
        </div>

        <div className="d-flex flex-column gap-3 justify-content-center w-100 mt-5">
          {isLoadingRequestTemplateById ? (
            <SkeletonUI number={5} />
          ) : requestTemplateById &&
            Object.keys(selectedTemplateObject).length ? (
            <Form
              name="validate_other"
              onFinish={handleSubmitFilledRequestForm}
              className="make__request__form"
            >
              <div className="make__request__form__title">
                <h4 className="h4">{selectedTemplateObject?.templateName}</h4>
              </div>

              {requestTemplateById &&
              selectedTemplateObject?.templateType === null ? (
                selectedTemplateObject?.questionsItems &&
                selectedTemplateObject?.questionsItems.map(
                  (question, index) => (
                    <TemplateRenderer
                      question={question}
                      handleSetAnswer={handleSetAnswer}
                      key={index}
                    />
                  )
                )
              ) : selectedTemplateObject?.templateType === 'expense-report' ? (
                <div
                  style={{
                    maxWidth: '80rem',
                    width: '100%',
                    margin: '0 auto',
                  }}
                >
                  <ExpenseReportTemplate
                    expenseReportAnswers={expenseReportAnswers}
                    setExpenseReportAnswers={setExpenseReportAnswers}
                  />
                </div>
              ) : null}

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{
                  backgroundColor: 'var(--clr-primary)',
                  width: 'fit-content',
                  margin: '0 auto',
                }}
                loading={loading}
              >
                Submit
              </Button>
            </Form>
          ) : (
            <div
              className="d-flex flex-column align-items-center justify-content-center gap-2"
              style={{
                height: '70vh',
              }}
            >
              <img
                src="/assets/reqmanagement.png"
                alt="reqmanagement"
                width="200"
              />
              <h4 className="h5">Welcome to Request Form</h4>
              <p>
                You can see all available request forms and select any of your
                choice
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MakeNewRequest;
