import { useParams } from 'react-router-dom';
import styles from '../../styles/permissions/ViewGroups.module.css';
import {
  Space,
  Input,
  Button,
  Tag,
  Popconfirm,
  Table,
  Pagination,
  Modal,
} from 'antd';
import { SearchOutlined, RedoOutlined } from '@ant-design/icons';
import { canAccess, convertToCapitalize } from '../../util/helpers';
import { useEffect, useMemo, useState } from 'react';
import {
  useGetUserInGroup,
  useGetPermissionsInGroup,
} from '../../util/preferenceshookActions';
import { permission_in_group } from '../../util/selectors/preferenceSelectors';
import {
  usePreferenceCleanUp,
  useShallowEqualSelector,
} from '../../util/hooks';
import { useDispatch } from 'react-redux';
import {
  RemoveEmployeeFromGroup,
  RemoveEmployeeFromGroupMultiple,
} from '../../redux/preference/preferenceActions';
import HocHeader from '../ui/HocHeader';
import SkeletonUI from '../ui/Skeleton';

function Group() {
  const dispatch = useDispatch();
  usePreferenceCleanUp();
  const { groupId, groupName } = useParams();
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <div className="p-1">
              <Input
                autoFocus
                placeholder="Filter Name"
                value={selectedKeys[0]}
                onPressEnter={() => {
                  confirm();
                }}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
              ></Input>
              <Space className="my-1">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    clearFilters();
                    confirm({ closeDropdown: false });
                  }}
                  icon={<RedoOutlined />}
                >
                  Reset
                </Button>
              </Space>
            </div>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record?.name?.toLowerCase().includes(value.toLowerCase());
      },

      render: (value, record) => {
        return value;
      },
    },
    {
      title: 'GENDER',
      dataIndex: 'sex',
      key: 'sex',
      width: '20%',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'DEPARTMENT',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'GROUPS',
      dataIndex: 'roles',
      key: 'roles',
      // render: (_, record) => {
      //   return record?.roles?.length
      //     ? record?.roles?.map((role, ind) => (
      //         <Link to={`/permissions/view-groups/${role.slug}/${role.id}`}>
      //           <Tag key={ind}>{convertToCapitalize(role.slug)}</Tag>
      //         </Link>
      //       ))
      //     : null;
      // },
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (_, record) => {
        const { r_id } = record;
        if (
          canAccess(
            'delete-user-from-group-human-resource-information-system-preferences'
          )
        )
          return (
            <Popconfirm
              placement="topRight"
              title="Remove User"
              description="Are you sure to remove this user?"
              onConfirm={() => {
                confirmAction(r_id, grpId);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tag color="red" style={{ cursor: 'pointer' }}>
                Remove
              </Tag>
            </Popconfirm>
          );
      },
    },
  ];

  const [grpId, setgrpId] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [page, setPage] = useState(1);
  const [enabled_perm, setEnabledPerm] = useState(false);
  const [emp_show, setEmpShow] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useGetPermissionsInGroup(enabled_perm, setEnabledPerm, grpId);

  const permissionInGrp = useShallowEqualSelector(permission_in_group);

  const { data: emp, isLoading: isLoadingEmp } = useGetUserInGroup(
    enabled,
    setEnabled,
    page,
    grpId
  );

  const emp_data = useMemo(() => emp?.payload?.data[0].attached_users, [emp]);

  useEffect(() => {
    if (groupId) {
      setgrpId(groupId);
      setEnabled(true);
      setEnabledPerm(true);
    }
  }, [groupId]);

  useEffect(() => {
    if (emp_data?.data?.length) {
      const new_arry = emp_data?.data.map((em) => {
        return {
          key: em.id,
          id: em.id,
          r_id: em.id,
          name: `${convertToCapitalize(em.fullname)}`,
          sex: em.gender || '--',
          status: em.status || '--',
          lastLogin: em.lastLogin || '--',
          department: em.Department || '--',
          roles: em.roles || '--',
        };
      });
      setEmpShow(new_arry);
    }
  }, [emp_data]);

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log(newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handlePaginationEmp = (page) => {
    setPage(page);
    setEnabled(true);
  };

  const confirmAction = (user_id, role_id) => {
    // console.log(user_id, role_id);
    RemoveEmployeeFromGroup(dispatch, { user_id, role_id }).then((res) => {
      if (res?.status === 'success') {
        const new_arry = [...emp_show];
        setEmpShow(new_arry.filter((item) => item.r_id !== user_id));
      }
    });
  };

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const confirm = () => {
    RemoveEmployeeFromGroupMultiple(dispatch, {
      role_id: grpId,
      user_ids: selectedRowKeys,
    }).then((res) => {
      setShowModal(false);
      if (res?.status === 'success') {
        const new_arry = [...emp_show];
        selectedRowKeys.forEach((id) => {
          setEmpShow(new_arry.filter((item) => item.r_id !== id));
        });
      }
    });
  };

  return (
    <>
      <HocHeader
        module_class="performance-header"
        module_title="Permissions Management"
        module_name={`${convertToCapitalize(groupName)} Details`}
        module_description="
    HR Managers and privileged administrators can view all your new
    hires, monitor their stages and manage their activities here."
        module_img="/assets/permission.svg"
      />

      <main className="main__wrapper">
        <main className={`${styles.main} container-fluid py-3`}>
          <div className="app__section py-4">
            <h3>Users</h3>

            {canAccess(
              'delete-user-from-group-human-resource-information-system-preferences'
            ) && (
              <Button
                type="primary"
                disabled={!selectedRowKeys.length}
                className="my-3 bg-danger"
                onClick={modalHandler}
              >
                Remove selected
              </Button>
            )}

            <Modal
              title={`Remove Selected`}
              centered
              onOk={() => confirm()}
              onCancel={modalHandler}
              open={showModal}
              cancelText="No"
              okText="Yes"
            >
              <p className="h6">
                Are you sure you want to remove the selected employee?
              </p>
            </Modal>
            {isLoadingEmp ? (
              <SkeletonUI />
            ) : (
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={emp_show}
                pagination={false}
                bordered
              />
            )}

            <div className="my-3 mb-5 d-flex justify-content-between">
              <Pagination
                total={emp_data?.meta?.last_page}
                pageSize={1}
                onChange={handlePaginationEmp}
              />
            </div>

            <h3>Permissions</h3>
            <div className="row">
              <div className="col-md-12">
                <table
                  id="detail_table1"
                  className="table table-hover table-bordered table-striped"
                  style={{
                    width: '100%',
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: '30%',
                        }}
                      >
                        Modules
                      </th>
                      <th>Permissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissionInGrp[0]?.permissions.length ? (
                      <>
                        {permissionInGrp[0]?.permissions.map((perm, index) => (
                          <>
                            <tr key={index}>
                              <th colspan="2"> {perm.module} </th>
                            </tr>

                            {perm.menus.map((men, index) => {
                              return men.permissions?.[0]?.active_on_role ? (
                                <tr key={index}>
                                  <td>
                                    <small>
                                      <i>{men.menu}</i>
                                    </small>
                                  </td>
                                  <td>
                                    <Space wrap>
                                      {men.permissions.map((prm, ind) => {
                                        return prm.active_on_role ? (
                                          <Tag color="red" key={ind}>
                                            {prm.description}
                                          </Tag>
                                        ) : null;
                                      })}
                                    </Space>
                                  </td>
                                </tr>
                              ) : null;
                            })}
                          </>
                        ))}
                      </>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </main>
    </>
  );
}

export default Group;
